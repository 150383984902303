<template>
    <section class="content-wrapper">
        <div class="row">
            <div class="col-12">
                <div class="box box-solid box-primary">
                    <div class="box-header">
                        <h4 class="box-subtitle text-white">Confirm Order</h4>
                    </div>
                    <div class="box-body">
                        <form @submit.prevent="onSubmit" class="form-horizontal form-element mb-20 p-30">
                            <div class="row">
                                <p v-if="error" class="error">{{errorMessage}}</p>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6 pr-60">
                                    <h3>Pickup Details</h3>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Name</label>
                                        <p>{{pickupName}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Phone</label>
                                        <p>{{pickupPhone}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Email</label>
                                        <p>{{pickupEmail}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Address</label>
                                        <p>{{pickupAddress}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup Country</label>
                                        <p>{{pickupCountryName}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup State</label>
                                        <p>{{pickupStateName}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Pickup City</label>
                                        <p>{{pickupCityName}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <h3>Delivery Details</h3>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Name</label>
                                        <p>{{deliveryName}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Phone</label>
                                        <p>{{deliveryPhone}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Email</label>
                                        <p>{{deliveryEmail}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Address</label>
                                        <p>{{deliveryAddress}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery Country</label>
                                        <p>{{deliveryCountryName}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery State</label>
                                        <p>{{deliveryStateName}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Delivery City</label>
                                        <p>{{deliveryCityName}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="showPriceEstimate" class="row mb-30 mt-30">
                                <div class="col-12 col-lg-12">
                                    <p class="text-center text-info">
                                        {{ computeEstimatedPrice }}
                                        <button v-if="!isNaN(estimatedPrice)" type="button" class="btn btn-info btn-xs" data-toggle="modal" data-target="#price-detail">View More</button>
                                        <PriceDetail :priceList="estimatedPriceList"/>
                                    </p>
                                </div>
                            </div> -->
                            <div class="row mb-30 mt-30">
                                <div class="col-12 col-md-6 col-lg-6 pr-60">
                                    <div class="form-group">
                                        <label class="text-info">Pickup Extra Information</label>
                                        <p>{{pickupExtraInformation ? pickupExtraInformation : 'None'}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-info">Item Description</label>
                                        <p>{{itemDescription}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label class="text-info">Delivery Extra Information</label>
                                        <p>{{deliveryExtraInformation ? deliveryExtraInformation : 'None'}}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-12">
                <div class="box box-solid box-primary">
                    <div class="box-header">
                        <h4 class="box-subtitle text-white">Confirm Order</h4>
                    </div>
                    <div class="box-body">
                        <form @submit.prevent="onSubmit" class="form-horizontal form-element pl-30 pr-30">
                            <div class="row">
                                <h3>Order Information</h3>
                                <p v-if="error" class="error">{{errorMessage}}</p>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select v-model="orderStatus" @change="onStatusValueChange" class="form-control">
                                            <option value="pending">Pending</option>
                                            <option v-if="!useSubscription" value="unpaid">Unpaid</option>
                                            <option v-if="!useSubscription" value="paid">Paid</option>
                                            <option value="processing">Processing</option>
                                            <option value="in-transit">In Transit</option>
                                            <option value="delivered">Delivered</option>
                                            <option value="cancelled">Cancelled</option>
                                        </select>
                                        <p class="error" v-if="orderStatusError">{{orderStatusErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <div class="controls">
                                            <textarea cols="4" rows="2" v-model="orderStatusComment" class="form-control" placeholder="Comment"></textarea>
                                        </div>
                                        <p class="error" v-if="orderStatusCommentError">{{orderStatusCommentErrorMessage}}</p>
                                    </div>
                                </div>
                                <div v-if="showUnpaidData && !useSubscription" class="col-md-4">
                                    <div class="form-group">
                                        <label>Item Weight</label>
                                        <select v-model="selectedWeight" @change="onItemWeightChange" class="form-control">
                                            <option value="">Select Weight</option>
                                            <option v-for="weight in supportedWeightList" :key="weight.id" :value="weight">{{weight.weight}}</option>
                                        </select>
                                        <p class="error" v-if="selectedWeightError">{{selectedWeightErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Delivery Cost</label>
                                        <input v-model="selectedWeightPrice" type="number" class="form-control"  placeholder="Price"/>
                                        <p class="error" v-if="selectedWeightPriceError">{{selectedWeightPriceErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Extra Charge</label>
                                        <input v-model="orderExtraCharge" type="number" class="form-control"  placeholder="Extra charge on order"/>
                                        <p class="error" v-if="orderExtraChargeError">{{orderExtraChargeErrorMessage}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label>Total Cost</label>
                                        <input v-model="orderTotalCost" type="number" class="form-control"  placeholder="Price" disabled/>
                                    </div>
                                    <div class="form-group">
                                        <label>Extra Charge Comment</label>
                                        <input v-model="orderExtraChargeComment" type="text" class="form-control"  placeholder="Comment for extra charge on this order"/>
                                    </div>
                                </div>
                                <div v-if="showPaidData && !useSubscription" class="col-md-4">
                                    <div class="form-group">
                                        <label>Who is paying?</label>
                                        <select v-model="paidBy" class="form-control">
                                            <option value="customer">Customer</option>
                                            <option value="admin">Company</option>
                                        </select>
                                    </div>
                                    <div v-if="paidBy=='customer'" class="form-group">
                                        <label>Mode of Payment</label>
                                        <select v-model="paymentMethod" class="form-control">
                                            <option value="wallet">Wallet</option>
                                            <option value="cash">Cash</option>
                                            <option value="transfer">Transfer</option>
                                            <option value="paystack">Paystack</option>
                                            <option value="flutterwave">Flutterwave</option>
                                        </select>
                                    </div>
                                    <div v-if="paidBy=='customer' && paymentMethod!='wallet'" class="form-group">
                                        <label>Receipt No/Reference</label>
                                        <input v-model="paymentReference" type="text" class="form-control"  placeholder="Reference/No"/>
                                        <p class="error" v-if="paymentReferenceError">{{paymentReferenceErrorMessage}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6 mt-60">
                                    <button @click="confirmOrder" class="btn btn-primary btn-large">Confirm Order<span v-if="loading" class="spinner-border text-white"></span></button>
                                </div>
                            </div>
                            <div class="row">
                                <p v-if="error" class="error">{{errorMessage}}</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { repository } from '../../presenter';
import { setTimeout } from 'timers';
export default {
    props: ['orderData'],
    data(){
        return{

            supportedWeightList: [],

            showUnpaidData: false,
            showPaidData: false,
            orderStatus: 'pending',
            orderStatusError: false,
            orderStatusErrorMessage: '',
            orderStatusComment: '',
            orderStatusCommentError: false,
            orderStatusCommentErrorMessage: '',
            selectedWeight: null,
            selectedWeightError: false,
            selectedWeightErrorMessage: '',
            selectedWeightPrice: 0,
            selectedWeightPriceError: false,
            selectedWeightPriceErrorMessage: '',
            orderExtraCharge: 0,
            orderExtraChargeError: false,
            orderExtraChargeErrorMessage: '',
            orderExtraChargeComment: '',
            orderTotalCost: '',
            paidBy: 'admin',
            paymentMethod: 'cash',
            paymentReference: '',
            paymentReferenceError: false,
            paymentReferenceErrorMessage: '',


        
            // customer : this.$route.params.customerData,
            loading: false,
            error: false,
            errorMessage: '',
            publicPath: process.env.BASE_URL,
            
        }
    },
    watch: {
        orderData: function(val) {
            console.log("order data is ", val);
        },
        selectedWeightPrice: function(val) {
            this.onCalculateTotalCost();
        },
        orderExtraCharge: function(val) {
            this.onCalculateTotalCost();
        },
    },
    computed: {
        pickupName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.pickupName
        },
        pickupPhone() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            return data.pickupPhone
        },
        pickupEmail() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            return data.pickupEmail
        },
        pickupAddress() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.pickupAddress
        },
        pickupCountryName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.pickupCountryName
        },
        pickupStateName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.pickupStateName
        },
        pickupCityName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.pickupCityName
        },
        deliveryName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryName
        },
        deliveryPhone() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryPhone
        },
        deliveryEmail() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            return data.deliveryEmail
        },
        deliveryAddress() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryAddress
        },
        deliveryCountryName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryCountryName
        },
        deliveryStateName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryStateName
        },
        deliveryCityName() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryCityName
        },
        pickupExtraInformation() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.pickupExtraInformation
        },
        deliveryExtraInformation() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.deliveryExtraInformation
        },
        itemDescription() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            console.log(data);
            return data.itemDescription
        },
        useSubscription() {
            let data = this.orderData ? JSON.parse(this.orderData) : '';
            return data.useSubscription
        },
    },

    mounted() {
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
        document.head.appendChild(sweetAlert)

        console.log(" from mounted order data is ", this.$route.params);
        console.log("order data form this.orderdata is ", this.orderData.pickupName);
        this.loadSupportedWeightList();
    },

    methods: {
        async confirmOrder() {
            if (this.loading) return
            this.resetData()
            const confirmOrderValidation = this.validateConfirmOrderData()
            if (!confirmOrderValidation.success) return
            this.loading = true
            const data = {
                orderID: [JSON.parse(this.orderData).orderID],
                userID: JSON.parse(this.orderData).userID,
                status: this.orderStatus,
                statusComment: this.orderStatusComment,
                weightID: this.selectedWeight ? this.selectedWeight.id : null,
                deliveryCost: this.selectedWeightPrice,
                extraCharge: this.orderExtraCharge,
                extraChargeComment: this.orderExtraChargeComment,
                paidBy: this.paidBy,
                paymentMethod: this.paymentMethod,
                paymentReference: this.paymentReference,
            }
            console.log('data is ', data)
            const response = await repository.order.confirmOrder(data);
            this.loading = false
            console.log('response is ', response)
            if (response.success) {
                this.showSuccessMessage('Order Created');
                return
            }
            this.showErrorMessage(response.data)
        },
        validateConfirmOrderData() {
            const returnValue = new Object()
            if (this.showUnpaidData && !this.useSubscription){
                if (!this.selectedWeight){
                    this.selectedWeightError=true;
                    this.selectedWeightErrorMessage='Select Item Weight';
                    returnValue.success=false;
                    return returnValue;
                }
                if (this.selectedWeightPrice < 0){
                    this.selectedWeightPriceError=true;
                    this.selectedWeightPriceErrorMessage='Price is invalid';
                    returnValue.success=false;
                    return returnValue;
                }
                if (this.orderExtraCharge < 0){
                    this.selectedWeightError=true;
                    this.selectedWeightErrorMessage='Extra charge is invalid';
                    returnValue.success=false;
                    return returnValue;
                }
            }
            if (this.showPaidData && !this.useSubscription){
                if (this.paidBy=='customer' && this.paymentMethod!='wallet' && !this.paymentReference){
                    this.paymentReferenceError=true;
                    this.paymentReferenceErrorMessage='Enter a payment reference';
                    returnValue.success=false;
                    return returnValue;
                }
            }
            
            returnValue.success = true
            return returnValue
        },
        resetData() {
            this.orderStatusError=false;
            this.paymentReferenceError=false;
            this.orderStatusCommentError=false;
            this.selectedWeightError=false;
            this.selectedWeightPriceError=false;
            this.orderExtraChargeError=false;
            
            this.error = false
            this.errorMessage = ''
        },
        showSuccessMessage (message) {
            this.loading=true;
            swal(message, "", "success")
            setTimeout(function(){
                window.location.href=`${process.env.BASE_URL}vieworders`
            }, 1000)
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },

        setDynamicData() {
            this.orderData.priceData.forEach(data => {
                if (data.supported_weight_id==this.pageData.order.item_weight_id) {
                    this.selectedWeight=data;
                    this.onItemWeightChange();
                    this.selectedWeightPrice = this.pageData.order.delivery_cost
                    return;
                }
            });

        },






        onStatusValueChange(){
            switch(this.orderStatus) {
                case 'pending':
                    this.showUnpaidData=false;
                    this.showPaidData=false;
                    break;
                case 'unpaid':
                    this.showUnpaidData=true;
                    this.showPaidData=false;
                    break;
                case 'paid':
                case 'processing':
                    this.showUnpaidData=true;
                    this.showPaidData=true;
                    break;
                case 'in-transit':
                case 'delivered':
                    this.showUnpaidData=true;
                    this.showPaidData=true;
                    break;
                default:
                    this.showUnpaidData=true;
                    this.showPaidData=true;  
            }
        },
        onItemWeightChange(){
            // this.selectedWeightPrice = this.selectedWeight.price ?? 0;
            // this.onCalculateTotalCost();
            if (this.orderData){
                for(let priceData of JSON.parse(this.orderData).priceData){
                    if (this.selectedWeight.id==priceData.supported_weight_id){
                        this.selectedWeightPrice = priceData.price;
                        this.onCalculateTotalCost();
                        break;
                    }
                    this.selectedWeightPrice = ""
                }
            }
        },

        onCalculateTotalCost() {
            this.orderTotalCost = Number(this.selectedWeightPrice) + Number(this.orderExtraCharge);
        },

        async loadSupportedWeightList() {
            const weightListResponse = await repository.regions.fetchSupportedWeightList();
            if (weightListResponse.success){
                this.supportedWeightList = weightListResponse.data;
                return;
            }
        },
    }
}
</script>


<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

